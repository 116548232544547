<template lang="pug">
include ../../configs/template
div(v-if="listDevice.length").text-left
  h4 Ubikey
  div.d-flex.flex-wrap
    v-card(v-for="ubikey in listDevice" :key="ubikey.id" min-width="400").m-1
      v-card-text.row
        div.col-sm-12.col-md-10
          div.row
            div.col-12
              +data-info('createDate', 'ubikey.created_at')
            div(v-if="ubikey.last_used_at").col-12
              +data-info('lastTimeUsed', 'ubikey.last_used_at')
            div(v-if="ubikey.type_protocol").col-12
              +data-info('typeProtocol', 'ubikey.type_protocol')
        div.col-sm-12.col-md-2.justify-end
          ControlButtons(:controlButtons="controlButtons" :item="ubikey").justify-end
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ListUserTOTP',
  components: {
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  props: {
    listDevice: { type: Array, default: () => [] }
  },
  data () {
    return {
      controlButtons: [
        {
          id: 1,
          name: 'mdi-delete-circle-outline',
          tooltip: 'tooltip.delete',
          action: (item) => this.deleteUbikey(item),
          color: 'error',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  methods: {
    ...mapActions(['removeUbikeyAdmin']),
    deleteUbikey (item) {
      this.$swal({
        icon: 'warning',
        title: this.$t('notify.title.warning'),
        text: this.$t('notify.warning.Do you sure that uou want remove device', { device: 'Ubikey' }),
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async confirm => {
        if (confirm) {
          await this.removeUbikeyAdmin({ id: item.id })
        }
      })
    }
  }
}
</script>
